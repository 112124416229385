import React from 'react';
import StepsView from './StepsView';
import { FindYourIokeeProvider } from '../provider/find-your-iokee.provider';

const FindYourIokeeSteps = () => (
	<main>
		<FindYourIokeeProvider>
			<StepsView />
		</FindYourIokeeProvider>
	</main>
);

export default FindYourIokeeSteps;
