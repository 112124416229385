import { BodyText } from 'common/presentation/components';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	Stack,
	useTheme,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { wallboxIcon } from 'assets/icons';
import Dimens from 'assets/dimens';
import ClientDatePicker from 'common/presentation/components/date-picker/ClientDatePicker';
import Space from 'common/presentation/components/space/Space';
import { IReportDate } from '../../entities/report';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { IDevice } from '../../entities/device';

const FIRST_DAY_OF_CURRENT_MONTH = new Date(new Date().setDate(1));

interface Props {
	open: boolean
	handleClose: () => void
	devices?: IDevice[]
	handleClickShowReport: (ids: number[], dates: IReportDate) => void

}

interface DeviceCardProps {
	device: IDevice
}

const DeviceCard = (props: DeviceCardProps) => {
	const { device } = props;
	const { name } = device;
	const theme = useTheme();
	return (
		<Stack direction="row" spacing={3} sx={{ mb: 2 }}>
			<img src={wallboxIcon} alt="Device" />
			<Box>
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_L,
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
				>
					{name}
				</BodyText>
				<BodyText sx={{
					fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
					color: theme.palette.textLight.main,
				}}
				>
					{`${device?.board?.productCode}${device?.board?.serialNumber}`}
				</BodyText>
			</Box>
		</Stack>
	);
};

const DeviceSelectForReportModal: FC<Props> = (props) => {
	const {
		open, handleClose, devices, handleClickShowReport,
	} = props;
	const { t } = useTranslation('translations');
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [dates, setDates] = useState<IReportDate>({
		startDate: FIRST_DAY_OF_CURRENT_MONTH,
		endDate: new Date(),
	});

	const addAll = () => {
		if (devices) {
			const ids = [] as number[];
			devices.forEach((el) => {
				ids.push(el.userProductID);
			});
			setSelectedIds(ids);
		}
	};

	useEffectCustom(() => {
		addAll();
	}, [devices]);

	const handleChange = (id: number) => {
		if (selectedIds.includes(id)) {
			setSelectedIds((prev) => prev.filter((el) => el !== id));
		} else {
			const mSelectedIds = [...selectedIds];
			mSelectedIds.push(id);
			setSelectedIds(mSelectedIds);
		}
	};

	const handleCheckAllClick = () => {
		if (selectedIds.length === devices?.length) {
			setSelectedIds([]);
		} else {
			addAll();
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{t('select_devices_for_report')}
			</DialogTitle>
			<DialogContent>
				<Stack direction="row" sx={{ mt: 4 }} spacing={2} justifyContent="space-around">
					<ClientDatePicker
						label={t('from')}
						value={dates.startDate}
						sx={{ width: '50%' }}
						onChange={(newValue) => setDates({ ...dates, startDate: newValue })}
					/>
					<ClientDatePicker
						label={t('until')}
						value={dates.endDate}
						sx={{ width: '50%' }}
						onChange={(newValue) => setDates({ ...dates, endDate: newValue })}
					/>
				</Stack>
				<Space height={4} />
				<List>
					<ListItem secondaryAction={(
						<Stack direction="row" alignItems="center">
							<BodyText>Select all</BodyText>
							<Checkbox
								edge="start"
								sx={{ ml: 1 }}
								checked={selectedIds.length === devices?.length}
								tabIndex={-1}
								onChange={() => handleCheckAllClick()}
								disableRipple
								inputProps={{ 'aria-labelledby': 'checkbox-list-label-select-all' }}
							/>
						</Stack>
					)}
					>
						<div />
					</ListItem>
					{devices && devices.map((el) => (
						<ListItem
							key={el.userProductID}
							secondaryAction={(
								<Checkbox
									edge="start"
									checked={selectedIds.includes(el.userProductID)}
									tabIndex={-1}
									onChange={() => { handleChange(el.userProductID); }}
									disableRipple
									inputProps={{ 'aria-labelledby': `checkbox-list-label-${el.userProductID}` }}
								/>
							)}
							disablePadding
						>
							<DeviceCard device={el} />
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={() => handleClose()}>
					{t('cancel')}
				</Button>
				<Button variant="contained" onClick={() => handleClickShowReport(selectedIds, dates)}>
					{t('show_report')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeviceSelectForReportModal;
