import React from 'react';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Stack, useTheme,
} from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import BodyText from '../body-text/BodyText';
import DefaultInput from '../default-input/DefaultInput';
import DefaultButton from '../default-button/DefaultButton';
import useCommunity from 'features/community/presentation/provider/community.provider';
import { validateEmail } from 'utils/validation';

const CommunityForm = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		joinCommunity,
		joinCommunityLoading,
		joinCommunityData,
		joinCommunityErrors,
		setJoinCommunityData,
		setJoinCommunityErrors,
	} = useCommunity();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setJoinCommunityData({
			...joinCommunityData,
			[event.target.name]: event.target.value,
		});
		setJoinCommunityErrors({
			...joinCommunityErrors,
			[event.target.name]: '',
		});
	};

	const handleJoinCommunity = () => {
		const errors = {
			fullName: '',
			email: '',
			country: '',
			privacyPolicy: '',
		};
		if (!joinCommunityData.fullName) {
			errors.fullName = t('required');
		}
		if (!joinCommunityData.email) {
			errors.email = t('required');
		}
		if (joinCommunityData.email && !validateEmail(joinCommunityData.email)) {
			errors.email = t('email_error');
		}
		if (!joinCommunityData.country) {
			errors.country = t('required');
		}
		if (!joinCommunityData.privacyPolicy) {
			errors.privacyPolicy = t('required');
		}
		if (errors.fullName || errors.email || errors.country || errors.privacyPolicy) {
			setJoinCommunityErrors(errors);
			return;
		}
		joinCommunity(joinCommunityData);
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mb: Dimens.FOOTER_PADDING_VERTICAL,
			mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
		}}
		>
			<BodyText
				size={Dimens.BODY_TEXT_L}
				sx={{
					mt: Dimens.TITLE_SPACING,
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
					fontSize: { md: Dimens.TITLE_TEXT_S, xs: Dimens.TITLE_TEXT_S_MOBILE },
				}}
			>
				{t('join_community').toUpperCase()}
			</BodyText>
			<Grid container spacing={3} sx={{ mt: Dimens.TITLE_SPACING }}>
				<Grid item md={4} xs={12}>
					<DefaultInput
						label={t('what_is_your_name')}
						placeholder={t('your_full_name')}
						value={joinCommunityData.fullName}
						name="fullName"
						error={!!joinCommunityErrors.fullName}
						helperText={joinCommunityErrors.fullName}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<DefaultInput
						label={t('what_is_your_email')}
						placeholder={t('your_email')}
						name="email"
						value={joinCommunityData.email}
						error={!!joinCommunityErrors.email}
						helperText={joinCommunityErrors.email}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<DefaultInput
						label={t('select_your_country')}
						placeholder={t('your_country')}
						name="country"
						value={joinCommunityData.country}
						error={!!joinCommunityErrors.country}
						helperText={joinCommunityErrors.country}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
			<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
				<FormGroup>
					<FormControlLabel
						control={(
							<Checkbox
								onChange={() => setJoinCommunityData({
									...joinCommunityData,
									privacyPolicy: !joinCommunityData.privacyPolicy,
								})}
								value={joinCommunityData.privacyPolicy}
								icon={<RadioButtonUnchecked color="primary" />}
								checkedIcon={<RadioButtonChecked />}
							/>
						)}
						sx={{ color: theme.palette.textMain.main }}
						label={t('acknowledge_privacy_policy')}
					/>
					<FormHelperText error={!!joinCommunityErrors.privacyPolicy}>
						{joinCommunityErrors.privacyPolicy}
					</FormHelperText>
				</FormGroup>
				<DefaultButton
					loading={joinCommunityLoading}
					onClick={handleJoinCommunity}
					sx={{
						mt: { xs: 2, md: 0 },
					}}
				>
					{t('subscribe_now')}
				</DefaultButton>
			</Stack>
		</Box>
	);
};

export default CommunityForm;
