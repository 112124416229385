import Dimens from 'assets/dimens';
import {
	Box,
	Skeleton,
	Stack,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	BodyText, SizedBox, TitleText,
} from 'common/presentation/components';
import { useLanding } from 'features/landing/presentation/provider/landing-provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import CategoryCard from '../components/CategoryCard';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import BannerQuizz from 'features/community/presentation/iokee-community/components/BannerQuizz';

const StoreHero = () => {
	const { t } = useTranslation('translations');
	const { getCategories, loading, categories } = useLanding();
	const navigate = useNavigate();

	useEffectCustom(() => {
		getCategories();
	}, []);

	const handleCategoryClick = (id: number) => {
		navigate(AppLinks.PRODUCT_CATEGORY_DETAIL.formatMap({ categoryId: id }));
	};

	return (
		<Box sx={{
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<TitleText size={Dimens.TITEL_TEXT_M} fontWeight={Dimens.FONT_WEIGHT_BOLD}>
				{t('meet_our_devices').toUpperCase()}
			</TitleText>
			<SizedBox height={1} />
			<BodyText fontSize={Dimens.SUBTITLE_TEXT}>
				{t('learn_each_of_them').toUpperCase()}
			</BodyText>
			{loading && [1, 2, 3].map((el) => (
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Skeleton key={el} height={600} width="27%" />
					<Skeleton key={el} height={600} width="30%" />
					<Skeleton key={el} height={600} width="27%" />
				</Stack>
			))}
			<SizedBox height={4} />
			<Stack justifyContent="center" direction="row" spacing={2} useFlexGap flexWrap="wrap">
				{categories.length !== 0 && categories.map((cat) => (
					<CategoryCard
						title={cat.name}
						image={cat.attachment.url}
						onClick={() => handleCategoryClick(cat.id)}
					/>
				))}
			</Stack>
			<Box sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<BannerQuizz />
			</Box>
		</Box>
	);
};

export default StoreHero;
