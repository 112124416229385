/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { FormikHelpers } from 'formik';
import { IBackofficeQuestion, IBackofficeQuestionRequest } from '../questions/entities/question.modal';
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { BackofficeQuestionApiImpl } from '../questions/data/question.api-impl';
import { useTranslation } from 'react-i18next';
import useFeedback from 'common/presentation/providers/feedback.provider';

interface FetchState {
	loading: boolean
	error: string
}

type Props = {
	formValues: IBackofficeQuestionRequest
	setFormValues: React.Dispatch<React.SetStateAction<IBackofficeQuestionRequest>>
	submit: (
		values: IBackofficeQuestionRequest,
		formikHelpers: FormikHelpers<IBackofficeQuestionRequest>,
		id?: string
	) => void;
	questionState: FetchState
	getQuestions: () => void
	questions: IBackofficeQuestion[]
	deleteQuestion: (id: number) => void
	getQuestionById: (id: string) => void
	questionById: IBackofficeQuestion | null
}

export const BackofficeQuestionContext = createContext({} as Props);

export const BackofficeQuestionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [formValues, setFormValues] = useState<IBackofficeQuestionRequest>({
		description: '',
		stepOrder: 0,
	});
	const [questionState, setQuestionState] = useState({ loading: false, error: '' });
	const [questions, setQuestions] = useState<IBackofficeQuestion[]>([] as IBackofficeQuestion[]);
	const [questionById, setQuestionById] = useState<IBackofficeQuestion | null>(null);
	const { t } = useTranslation('translations');
	const { addDialog } = useFeedback();
	const QuestionApi = new BackofficeQuestionApiImpl();

	const submit = async (
		values: IBackofficeQuestionRequest,
		formikHelpers: FormikHelpers<IBackofficeQuestionRequest>,
		id?: string,
	) => {
		formikHelpers.setSubmitting(true);
		if (id) {
			try {
				const updateQuestionRes = await QuestionApi.editBackofficeQuestion(values, id);
				console.log(updateQuestionRes);
				addDialog({
					title: t('success'),
					message: '',
					error: false,
				});
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				formikHelpers.setSubmitting(false);
			}
		} else {
			try {
				const updateQuestionRes = await QuestionApi.addBackofficeQuestion(values);
				console.log(updateQuestionRes);
				addDialog({
					title: t('success'),
					message: '',
					error: false,
				});
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				formikHelpers.setSubmitting(false);
			}
		}
	};

	const getQuestions = async () => {
		setQuestionState({ error: '', loading: true });
		try {
			const questionRes = await QuestionApi.getBackofficeQuestions();
			setQuestions(questionRes);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
			setQuestionState({ error: error.message, loading: true });
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const deleteQuestion = async (id: number) => {
		setQuestionState({ error: '', loading: true });
		try {
			const updateQuestionRes = await QuestionApi.deleteQuestionById(id);
			console.log(updateQuestionRes);
			addDialog({
				title: t('success'),
				message: '',
				error: false,
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const getQuestionById = async (id: string) => {
		setQuestionState({ error: '', loading: true });
		try {
			const getQuestionRes = await QuestionApi.getQuestionById(id);
			setQuestionById(getQuestionRes);
			setFormValues({
				description: getQuestionRes.description,
				stepOrder: getQuestionRes.stepOrder,
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const value = useMemo(() => ({
		formValues,
		questionState,
		setFormValues,
		submit,
		getQuestions,
		questions,
		deleteQuestion,
		getQuestionById,
		questionById,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [formValues, questionState, questions]);

	return (
		<BackofficeQuestionContext.Provider value={value}>
			{children}
		</BackofficeQuestionContext.Provider>
	);
};

export function useBackofficeQuestions() {
	return useContext(BackofficeQuestionContext);
}
