/* eslint-disable no-console */
import React from 'react';
import {
	useTheme, Stack, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	BodyText, TitleText, CommunityForm, DefaultButton,
} from 'common/presentation/components';
import Dimens from 'assets/dimens';
import useCart from 'common/presentation/providers/cart.provider';
import { ICartProduct } from 'common/domain/entities/cart-product';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import CartItem from '../components/CartItem';
import ProductSummaryCard from '../components/ProductSummaryCard';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { ArrowBack } from '@mui/icons-material';

const ProductSummary = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const navigate = useNavigate();

	const {
		cartProducts,
		getTotalPrice,
		removeFromCart,
		incrementProduct,
		decrementCart,
	} = useCart();

	const handleDeleteClick = (product: ICartProduct) => {
		removeFromCart(product);
	};

	const handleContinueShoppingCart = () => {
		navigate(AppLinks.STORE);
	};

	const handleFinalizeClick = () => {
		navigate(AppLinks.FINALIZE_ORDER);
	};

	return (
		<CommunityProvier>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			}}
			>
				<TitleText
					sx={{ fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE } }}
					color={theme.palette.textMain.main}
					dangerouslySetInnerHTML={{ __html: t('shopping_cart_html') }}
				/>
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					columnGap={1}
					justifyContent="space-between"
					alignItems="flex-start"
					flexWrap="wrap"
				>
					<Stack width={{ xs: Dimens.CHECKOUT_CARD_WIDTH, md: Dimens.MD_CHECKOUT_CARD_AREA }}>
						<Stack direction="row" alignItems="center" justifyContent="flex-end">
							<BodyText fontSize={Dimens.BODY_TEXT_S}>
								{cartProducts.length}
								{' '}
								{t('product_added')}
							</BodyText>
						</Stack>
						<Stack>
							{cartProducts.length === 0 && (
								<BodyText textAlign="center" sx={{ mt: 4, color: theme.palette.error.main }}>
									{t('no_cart_items')}
								</BodyText>
							)}
							{cartProducts.map((product) => (
								<CartItem
									title={product.name}
									image={product.image}
									price={product.price}
									originalPrice={product.originalPrice}
									storageFee={product.storageFee}
									freeStorageTime={product.freeStorageTime}
									count={product.qty}
									onDeleteClick={() => handleDeleteClick(product)}
									incrementProduct={() => incrementProduct(product)}
									decrementProduct={() => decrementCart(product)}
								/>
							))}
						</Stack>
					</Stack>
					<Stack
						width={{ xs: '100%', md: '35%' }}
						sx={{ mt: { md: 0, xs: Dimens.SECTION_SPACING_MOBILE } }}
						justifyContent="flex-end"
						alignItems={{ md: 'flex-end', xs: 'center' }}
					>
						{cartProducts.length > 0 && (
							<ProductSummaryCard
								totalPrice={getTotalPrice()}
								onFinalizeClick={handleFinalizeClick}
								buttonText={t('make_contact_request')}
							/>
						)}
					</Stack>

				</Stack>
				<Stack
					alignItems="center"
					sx={{ mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.SECTION_SPACING_MOBILE } }}
				>
					<BodyText sx={{ fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_S } }}>
						{t('Esqueceu-se de alguma coisa?').toUpperCase()}
					</BodyText>
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						marginTop: {
							md: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL,
							xs: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL_MOBILE,
						},
					}}
					>
						<DefaultButton
							variant="outlined"
							onClick={handleContinueShoppingCart}
							startIcon={<ArrowBack />}
							endIcon={null}
						>
							{t('Continuar a comprar')}
						</DefaultButton>
					</Box>
				</Stack>
			</Box>
			<CommunityForm />
		</CommunityProvier>
	);
};

export default ProductSummary;
