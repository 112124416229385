/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { storeGet, storePatch } from 'common/data/remote/store-requests';
import { IOrder } from '../../domain/entities/order';
import { OrderBackofficeApi } from './order-api';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';

export class OrderBackofficeApiImpl implements OrderBackofficeApi {
	async getOrders(): Promise<IOrder[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_ORDERS,
			});
			const data = resp.data as ApiResponse<IOrder[]>;
			return data.data;
		} catch (error) {
			throw error;
		}
	}

	async changeOrderStatus(id: number, status: number): Promise<number> {
		try {
			const resp = await storePatch({
				url: Endpoints.STORE_ORDER_STATUS.format(id, status),
			});
			const data = resp.data as ApiResponse<IOrder[]>;
			return status;
		} catch (error) {
			throw error;
		}
	}
}
