/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import {
	AppBar as MuiAppBar,
	Box, Stack, Toolbar,
	Typography, IconButton,
	SvgIcon, useMediaQuery, Theme, Slide, useScrollTrigger, Badge, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
	HambergerMenu, logoBlack, logoBlackMobile,
} from 'assets/icons';
import Dimens from 'assets/dimens';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import LanguageSelector from '../language-selector/LanguageSelector';
import DefaultButton from '../default-button/DefaultButton';
import useEffectCustom from '../../hooks/useEffectCustom';
import useCart from '../../providers/cart.provider';
import NavMenuMobile from '../nav-menu-mobile/NavMenuMobile';
import { AccountCircleOutlined, SearchOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import SearchModal from '../search-modal/SearchModal';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}
type RouteTextProps = {
  bold: boolean
}

const HideOnScroll = (props: Props) => {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
};

const Appbar = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { palette } = useTheme();
	const { cartProducts } = useCart();

	const [appLogo, setAppLogo] = React.useState(logoBlack);
	const [navMenuOpen, setNavMenuOpen] = React.useState(false);
	const [searchDialogOpen, setSearchDialogOpen] = useState(false);
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const [logoWidth, setLogoWidth] = React.useState('');
	const location = useLocation();

	const RouteText = styled(Typography)<RouteTextProps>(({ theme, bold }) => ({
		color: theme.palette.textMain.main,
		cursor: 'pointer',
		fontWeight: bold ? Dimens.FONT_WEIGHT_BOLD : Dimens.FONT_WEIGHT_NORMAL,
		fontSize: Dimens.TEXT_DEFAULT,
		fontStyle: 'normal',
	}));

	const linkStyle = {
		textDecoration: 'none',
	};

	useEffectCustom(() => {
		if (isMd) {
			setAppLogo(logoBlack);
			setLogoWidth('');
		} else {
			setAppLogo(logoBlackMobile);
			setLogoWidth('50px');
		}
	}, [isMd]);

	const handleClientAreaClick = () => {
		navigate(AppLinks.CLIENT_AREA_LOGIN);
	};

	const handleOpenNavMenu = () => {
		setNavMenuOpen(true);
	};

	const handleNavMenuClose = () => {
		setNavMenuOpen(false);
	};

	const handleSearchClick = () => {
		setSearchDialogOpen(true);
	};

	return (
		<header>
			<HideOnScroll>
				<Box sx={{ flexGrow: 1 }}>
					<MuiAppBar
						position="fixed"
						elevation={2}
						sx={{
							pt: 3,
							bgcolor: palette.background.default,
							pr: {
								md: Dimens.APP_BAR_PADDING_HORIZANTAL,
								xs: Dimens.PAGE_PADDING_MOBILE,
							},
							pl: {
								md: Dimens.APP_BAR_PADDING_HORIZANTAL,
								xs: Dimens.PAGE_PADDING_MOBILE,
							},
						}}
					>
						<Toolbar disableGutters>
							<Box sx={{
								display: 'flex',
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
							>
								<Stack sx={{ mr: 1 }} direction="row">
									{!isMd && (
										<IconButton sx={{ color: palette.textMain.main }} onClick={handleOpenNavMenu}>
											<HambergerMenu />
										</IconButton>
									)}
									<Link to={AppLinks.LANDING}>
										<img src={appLogo} width={logoWidth} alt="logo" />
									</Link>
								</Stack>
								<Box sx={{ display: { md: 'none', xs: 'flex' }, alignItems: 'center' }}>
									<Link to={AppLinks.CHECKOUT_PAGE} style={{ ...linkStyle, marginRight: '10px' }}>
										<Badge badgeContent={cartProducts.length} color="primary">
											<SvgIcon sx={{ color: palette.textMain.main }}>
												<ShoppingCartOutlined />
											</SvgIcon>
										</Badge>
									</Link>
								</Box>
								<Box sx={{
									display: { md: 'flex', xs: 'none' },
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									flex: 1,
								}}
								>
									<Stack
										direction="row"
										justifyContent="space-evenly"
										sx={{
											flex: 1,
										}}
									>
										{/* <Link to={AppLinks.FIND_YOUR_IOKEE} style={linkStyle}>
											<RouteText
												bold={location.pathname.includes(AppLinks.FIND_YOUR_IOKEE)}
											>
												{t('find_your_iokee')}
											</RouteText>
										</Link> */}
										<Link to={AppLinks.STORE} style={linkStyle}>
											<RouteText
												bold={location.pathname.includes(AppLinks.STORE)}
											>
												{t('store')}
											</RouteText>
										</Link>
										<Link to={AppLinks.MOBILE_APP} style={linkStyle}>
											<RouteText
												bold={location.pathname.includes(AppLinks.MOBILE_APP)}
											>
												{t('app')}
											</RouteText>
										</Link>
										<Link to={AppLinks.ABOUT_US_PAGE} style={linkStyle}>
											<RouteText
												bold={location.pathname.includes(AppLinks.ABOUT_US_PAGE)}
											>
												{t('about_us')}
											</RouteText>
										</Link>
										<Link to={AppLinks.COMMUNITY} style={linkStyle}>
											<RouteText
												bold={location.pathname.includes(AppLinks.COMMUNITY)}
											>
												{t('community')}
											</RouteText>
										</Link>
										<Link to={AppLinks.CONTACT_US} style={linkStyle}>
											<RouteText
												bold={location.pathname.includes(AppLinks.CONTACT_US)}
											>
												{t('contact_us')}
											</RouteText>
										</Link>
										<IconButton
											sx={{ border: `1px solid ${palette.textMain.main}` }}
											onClick={handleSearchClick}
										>
											<SvgIcon sx={{ color: palette.textMain.main }}>
												<SearchOutlined />
											</SvgIcon>
										</IconButton>
										<Stack direction="row" alignItems="center" spacing={2}>
											<Link
												to={AppLinks.CHECKOUT_PAGE}
												style={{ ...linkStyle, marginRight: '10px' }}
											>
												<Badge badgeContent={cartProducts.length} color="primary">
													<SvgIcon sx={{ color: palette.textMain.main }}>
														<ShoppingCartOutlined />
													</SvgIcon>
												</Badge>
											</Link>
											<Link
												to={AppLinks.USER_LOGIN}
												style={{ ...linkStyle, marginRight: '10px' }}
											>
												<SvgIcon sx={{ color: palette.textMain.main }}>
													<AccountCircleOutlined />
												</SvgIcon>
											</Link>
										</Stack>
									</Stack>
									<DefaultButton
										variant="contained"
										onClick={handleClientAreaClick}
									>
										{t('client_area')}
									</DefaultButton>
								</Box>
							</Box>
							<Box sx={{
								display: { md: 'block', xs: 'none' },
								ml: 10,
								position: 'absolute',
								top: 0,
								right: -110,
							}}
							>
								<LanguageSelector />
							</Box>
						</Toolbar>
					</MuiAppBar>
				</Box>
			</HideOnScroll>
			<NavMenuMobile open={navMenuOpen} handleClose={handleNavMenuClose} />
			<SearchModal open={searchDialogOpen} handleClose={() => setSearchDialogOpen(false)} />
		</header>
	);
};

export default React.memo(Appbar);
