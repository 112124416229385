import React from 'react';
import { ProductCategoriesProvider } from './features/providers/product-categories.provider';
import { BackOfficeMain } from './BackofficeMain';
import { BackofficeProductProvider } from './features/providers/product.provider';
import { BackofficeOrderProvider } from './features/providers/order.provider';
import {
	CommunityBackofficeProvider,
} from './features/community-requests/presentation/provider/community-req-provider';
import { BackofficeQuestionsProvider } from './features/providers/question.provider';

const Backoffice = () => (
	<ProductCategoriesProvider>
		<BackofficeProductProvider>
			<BackofficeOrderProvider>
				<CommunityBackofficeProvider>
					<BackofficeQuestionsProvider>
						<BackOfficeMain />
					</BackofficeQuestionsProvider>
				</CommunityBackofficeProvider>
			</BackofficeOrderProvider>
		</BackofficeProductProvider>
	</ProductCategoriesProvider>
);

export default Backoffice;
