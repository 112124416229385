import { BodyText } from 'common/presentation/components';
import {
	Box,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	styled,
	useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeOrders } from '../../providers/order.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { OrderRow } from './components/OrderRow';
import Autocomplete from 'features/backoffice/common/presentation/auto-complete/Autocomplete';
import { IOrder } from '../domain/entities/order';

const BackofficeOrdersPage = () => {
	const { t } = useTranslation('translations');
	const {
		getOrders,
		orders,
		changeStatus,
		loading,
	} = useBackofficeOrders();
	const theme = useTheme();
	const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);
	useEffectCustom(() => {
		getOrders();
	}, []);

	const ORDER_STATUSES = [
		{ label: t('new'), id: 1 },
		{ label: t('in_progress'), id: 2 },
		{ label: t('served'), id: 3 },
		{ label: t('rejected'), id: 4 },
	];

	const StyledTableRow = styled(TableRow)(() => ({
		padding: '34px',
		backgroundColor: theme.palette.action.hover,
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	const handleChangeStatus = async (order?: { id: number, label: string } | null) => {
		if (!order || !selectedOrder) {
			return;
		}
		changeStatus(selectedOrder?.id, order.id);
	};

	return (
		<Box>
			<Stack direction="row" spacing={2}>
				<BodyText>
					{t('orders')}
				</BodyText>
				<Box sx={{ flexGrow: 1 }} />
				<Box sx={{ width: { md: '15%', xs: '40%' } }}>
					{selectedOrder && (
						<Autocomplete
							options={ORDER_STATUSES}
							disabled={loading}
							defaultValue={ORDER_STATUSES.find((el) => el.id === selectedOrder?.status)}
							label={t('change_status')}
							placeholder={t('select_new_status')}
							getOptionLabel={(option) => option.label}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>{option.label}</li>
							)}
							onChange={(_, option) => handleChangeStatus(option)}
						/>
					)}
				</Box>
			</Stack>
			<Box
				sx={{
					width: '100%',
					display: 'table',
					tableLayout: 'fixed',
					bgcolor: theme.palette.background.paper,
				}}
				mt={6}
			>
				{loading && (
					<Stack alignItems="center">
						<CircularProgress />
					</Stack>
				)}
				<Table sx={{ minWidth: 1000 }} size="small" aria-label="a dense table">
					<TableHead sx={{ bgcolor: theme.palette.action.hover }}>
						<TableRow>
							<TableCell>{t('ID')}</TableCell>
							<TableCell align="right">{t('user_name')}</TableCell>
							<TableCell align="center">{t('email')}</TableCell>
							<TableCell align="right">{t('phone')}</TableCell>
							<TableCell align="right">{t('date')}</TableCell>
							<TableCell align="right">{t('status')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orders.map((row) => (
							<OrderRow setRowSelected={setSelectedOrder} rowSelected={selectedOrder} data={row} />
						))}
						{orders.length === 0 && (
							<StyledTableRow
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="center" colSpan={6}>{t('no_orders')}</TableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</Box>
		</Box>
	);
};

export default BackofficeOrdersPage;
