import Dimens from 'assets/dimens';
import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack, SvgIcon } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxCard from '../components/CheckboxCard';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { SolarPanelIcon } from 'assets/find-your-iokee-icons';
import useFindYourIokee from '../provider/find-your-iokee.provider';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
}

const SolarPanelSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick } = props;
	const { t } = useTranslation('translations');
	const { handleSetFindYourIokeeData, findYourIokeeData } = useFindYourIokee();
	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 6 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('do_you_have_solar_panels').toUpperCase() }}
			/>
			<Stack direction="row" spacing={4} alignItems="flex-end">
				<CheckboxCard
					selected={findYourIokeeData.solarPanels}
					title={t('yes').toUpperCase()}
					onClick={() => handleSetFindYourIokeeData('solarPanels', true)}
				/>
				<SvgIcon sx={{ fontSize: { md: 100, xs: 80, sm: 80 } }} color="inherit" component="div" inheritViewBox>
					<SolarPanelIcon />
				</SvgIcon>
				<CheckboxCard
					selected={!findYourIokeeData.solarPanels}
					title={t('no').toUpperCase()}
					onClick={() => handleSetFindYourIokeeData('solarPanels', false)}
				/>
			</Stack>
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default SolarPanelSection;
