import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { communityQuiz, groupQuiz } from 'assets/images';
import { DefaultButton, TitleText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

const BannerQuizz = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const handleTakeTheQuizz = () => {
		navigate(AppLinks.FIND_YOUR_IOKEE);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: { xs: 'column', sm: 'column', md: 'row' },
				alignItems: 'center',
				justifyContent: { xs: 'center', sm: 'center', md: 'space-between' },
				backgroundImage: `url(${communityQuiz})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				borderRadius: Dimens.COMMUNITY_CARD_SM_BORDER_RADIUS,
				p: 3,
				mt: 10,
				height: { md: Dimens.SOCIAL_MEDIA_MODEL_HEIGHT },
				width: Dimens.COMMUNITY_CARD_WIDTH,
				border: `2px solid ${theme.palette.success.contrastText}`,
				textAlign: { xs: 'center', sm: 'center', md: 'left' },
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, sm: 2, md: 0 } }}>
				<img src={groupQuiz} alt="" />
			</Box>
			<Stack alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }} mb={{ xs: 2, sm: 2, md: 0 }}>
				<TitleText
					fontWeight={Dimens.FONT_WEIGHT_NORMAL}
					sx={{
						fontSize: {
							xs: Dimens.BODY_TEXT_MS,
							sm: Dimens.BODY_TEXT_L,
							md: Dimens.MD_SUMMARY_CARD_TITLE,
						},
					}}
				>
					{t('still_dont_know_what_iokee')}
				</TitleText>
				<TitleText
					fontWeight={Dimens.FONT_WEIGHT_NORMAL}
					sx={{
						fontSize: {
							xs: Dimens.BODY_TEXT_MS,
							sm: Dimens.BODY_TEXT_L,
							md: Dimens.MD_SUMMARY_CARD_TITLE,
						},
					}}
				>
					{t('find_my_iokee_quiz')}
				</TitleText>
			</Stack>
			<DefaultButton
				onClick={handleTakeTheQuizz}
				sx={{
					backgroundColor: theme.palette.background.default,
					transition: 'transform 0.3s ease-in-out',
					':hover': {
						transform: 'scale(0.95)',
						fontSize: 'calc(100% - 2px)',
					},

				}}
			>
				{t('take_the_quizz')}
			</DefaultButton>
		</Box>
	);
};

export default BannerQuizz;
