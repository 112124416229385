/* eslint-disable no-console */
import { BodyText, CounterButton, DefaultButton } from 'common/presentation/components';
import {
	Box, Stack, SvgIcon, useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { ArrowDown } from 'assets/icons';

interface Props {
	title: string
	image: string
	price: number
	count: number
	originalPrice: number
	storageFee: number
	freeStorageTime: number
	onDeleteClick: () => void
	incrementProduct: () => void
	decrementProduct: () => void
}

const CartItem: React.FC<Props> = (props) => {
	const {
		title,
		image,
		price,
		count,
		onDeleteClick,
		incrementProduct,
		decrementProduct,
		originalPrice,
		storageFee,
		freeStorageTime,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const getItemPrice = () => price * count;

	const getItemOriginalPrice = () => originalPrice * count;

	const deleteIcon = (
		<SvgIcon>
			<Delete sx={{ color: { md: theme.palette.primary.main, xs: theme.palette.error.main } }} />
		</SvgIcon>
	);

	return (
		<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" mt={8} alignItems="center">
			<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
				<Box>
					<img src={image} alt="Cart Items" height={Dimens.CART_ITEM_IMAGE_HEIGHT} width={Dimens.CART_ITEM_IMAGE_WIDTH} />
				</Box>
				<Box>
					<BodyText sx={{ fontSize: Dimens.BODY_TEXT_M, fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: title }} />
					<BodyText>
						{t('three_years_warranty')}
					</BodyText>
					<BodyText>
						{t('iokee_energy_analyzer_storage').format(storageFee, freeStorageTime)}
					</BodyText>
					<Stack direction="row" mt={2} spacing={2}>
						<DefaultButton sx={{ p: 0, m: 0 }} color="inherit" endIcon={<ArrowDown />} variant="text">{t('whats_in_iokee_box')}</DefaultButton>
					</Stack>
				</Box>
			</Stack>
			<Stack direction="row" alignItems={{ md: 'center', xs: 'flex-end' }} justifyContent="space-between" spacing={2} sx={{ mt: { xs: 2, md: 0 } }}>
				<CounterButton count={count} increment={incrementProduct} decrement={decrementProduct} />
				<Box sx={{ display: 'flex' }}>
					<BodyText pr={2}>
						<s>
							{getItemOriginalPrice().toFixed(2)}
						</s>
						{'  '}
						{getItemPrice().toFixed(2)}
						{' €'}
					</BodyText>
					<Box sx={{ cursor: 'pointer' }} component="span" onClick={onDeleteClick}>
						{deleteIcon}
					</Box>
				</Box>
			</Stack>
		</Stack>
	);
};

export default CartItem;
