import { Either } from '@ajtec/either-typescript';
import { IOrderBackofficeRepository } from '../repository/order-repository';
import { AxiosError } from 'axios';

export class ChangeOrderStatus {
	repository: IOrderBackofficeRepository;

	constructor(orderRepository: IOrderBackofficeRepository) {
		this.repository = orderRepository;
	}

	call(id: number, status: number): Promise<Either<AxiosError, number>> {
		return this.repository.changeOrderStatus(id, status);
	}
}
