import React, { useEffect, useMemo } from 'react';
import {
	Box, Grid, Stack, useTheme,
} from '@mui/material';
import DetailCard from './DetailCard';
import DetailItem from './DetailItem';
import Graph from './Graph';
import Dimens from 'assets/dimens';
import PersonIcon from '@mui/icons-material/Person';
import { BodyText } from 'common/presentation/components';
import EnergyPriceSummary from './EnergyPriceSummary';
import { IPageItem } from '@/features/client-wallbox-history/entities/charges.model';
import moment from 'moment';
import { useWallbox } from '../../provider/wallbox.provider';
import { useTranslation } from 'react-i18next';
import { ChargeEventTypeEnum } from 'features/client-wallbox-history/entities/charge-event.model';

interface AccordionContent{
	charge?: IPageItem
}

const AccordionContent: React.FC<AccordionContent> = ({
	charge,
}) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const { chargeEventsData, getWallboxEvent, loadingEvents } = useWallbox();

	useEffect(() => {
		if (charge) {
			getWallboxEvent(charge.chargeID, 1, 100);
		}
	}, [charge, getWallboxEvent]);

	const startDate = useMemo(() => () => {
		if (charge) {
			return moment(charge.startLocal, 'YYYY-MM-DD HH:mm:ss');
		}
		return null;
	}, [charge]);

	const endDate = useMemo(() => () => {
		if (charge) {
			return moment(charge.endLocal, 'YYYY-MM-DD HH:mm:ss');
		}
		return null;
	}, [charge]);

	const graphAxisX = useMemo(() => (
		chargeEventsData?.pageItems
			?.reverse()?.map((ev) => moment(ev.dateTimeLocal, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'))
	) ?? [], [chargeEventsData]);

	const graphAxisY = useMemo(() => (
		chargeEventsData?.pageItems?.reverse()?.map((ev) => (ev.energy ?? 0) / 1000)
	) ?? [], [chargeEventsData]);

	const getLabelFromType = (typeId: number) => {
		if (typeId === ChargeEventTypeEnum.START) {
			return t('start');
		} if (typeId === ChargeEventTypeEnum.END) {
			return t('end');
		} if (typeId === ChargeEventTypeEnum.PERIOD) {
			return t('fifteen');
		}
		return t('invalid_type');
	};

	return (
		<Box
			sx={{
				padding: Dimens.CARD_PADDING,
				backgroundColor: theme.palette.background.default,
				borderRadius: Dimens.CARD_BORDER_RADIUS,
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<DetailCard
						label="Início"
						value={startDate?.call(0)?.format('HH:mm') ?? ''}
						subValue={startDate?.call(0)?.format('DD/MM/YYYY') ?? ''}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<DetailCard
						label="Fim"
						value={endDate?.call(0)?.format('HH:mm') ?? ''}
						subValue={endDate?.call(0)?.format('DD/MM/YYYY') ?? ''}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							backgroundColor: theme.palette.background.paper,
							px: 2,
							py: 1,
							borderRadius: 2,
							boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
							maxWidth: 400,
							mx: 'auto',
							justifyContent: 'center',
							textAlign: 'center',
							mt: 1,
						}}
					>
						<PersonIcon sx={{ color: theme.palette.textLight.main, marginRight: '8px' }} />
						<BodyText
							sx={{
								color: theme.palette.textLight.main,
								fontWeight: Dimens.FONT_WEIGHT_BOLD,
								fontSize: Dimens.BODY_TEXT_S,
							}}
						>
							{charge?.initiator?.email}
						</BodyText>
					</Box>
					<Box sx={{ mt: 2 }}>
						<EnergyPriceSummary
							cost={charge?.energyCost ?? 0}
							consumption={(charge?.energyConsumption ?? 0) / 1000}
						/>
					</Box>
				</Grid>
			</Grid>
			{loadingEvents && (
				<div>{t('loading')}</div>
			)}
			{/* The graph and detail items side by side in 50% width each */}
			<Grid container spacing={2} sx={{ mt: Dimens.TITLE_BODY_SPACING }}>
				<Grid item xs={12} md={6}>
					<Graph axisX={graphAxisX} axisY={graphAxisY} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Stack sx={{ paddingLeft: Dimens.CARD_PADDING }}>
						{chargeEventsData?.pageItems.map((item) => (
							<DetailItem
								key={item.chargeEventID.toString()}
								label={getLabelFromType(item.type.id)}
								tag={item.rateType}
								consumption={item.energy}
								cost={item.energyCost}
								date={moment(item.dateTimeLocal, 'YYYY-MM-DD HH:mm:ss')
									.format('DD, MMMM YYYY')}
								time={moment(item.dateTimeLocal, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
							/>
						))}
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default AccordionContent;
