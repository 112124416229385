import { BodyText, SizedBox } from 'common/presentation/components';
import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { useStoreProducts } from '../provider/product-provider';

const MonophaseHidden = () => {
	const { productById, loading } = useStoreProducts();
	return (
		<Stack
			direction={{ md: 'row', xs: 'column' }}
			flexWrap="wrap"
			useFlexGap
			spacing={{ md: 20, xs: 4 }}
			sx={{ mt: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE } }}
		>
			{loading && [1, 2, 3].map((el) => (
				<Skeleton key={el} height={400} width="25%" />
			))}
			{productById?.specifications?.map((spec) => (
				<Box>
					<BodyText color="primary.main" sx={{ fontSize: Dimens.BODY_TEXT_MS }}>
						{spec.header.toUpperCase()}
					</BodyText>
					<SizedBox height={2} />
					<BodyText sx={{
						fontSize: Dimens.BODY_TEXT_S,
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
					}}
					>
						{spec.description}
					</BodyText>
				</Box>
			))}
		</Stack>
	);
};

export default MonophaseHidden;
