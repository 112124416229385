import Dimens from 'assets/dimens';
import { ElectricCarIcon } from 'assets/find-your-iokee-icons';
import {
	DefaultButton,
	DefaultDropdown,
	TitleText,
} from 'common/presentation/components';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Stack, SvgIcon } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxCard from '../components/CheckboxCard';
import useFindYourIokee from '../provider/find-your-iokee.provider';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
}

const ElectricCarSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick } = props;
	const { t } = useTranslation('translations');
	const { handleSetFindYourIokeeData, findYourIokeeData } = useFindYourIokee();
	const [isNumberOfCarView, setIsNumberOfCarsView] = useState(false);

	const handleContinueClick = () => {
		if (!isNumberOfCarView && findYourIokeeData.electricCars) {
			setIsNumberOfCarsView(true);
		} else {
			onContinueClick();
		}
	};

	const handleNumberOfCarsChange = (newVal: string) => {
		handleSetFindYourIokeeData('electicCarsQuantity', newVal);
	};

	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 6 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('do_you_have_electric_cars').toUpperCase() }}
			/>
			<Stack direction="row" spacing={6} alignItems="center">
				{!isNumberOfCarView && (
					<CheckboxCard
						selected={findYourIokeeData.electricCars}
						title={t('yes').toUpperCase()}
						onClick={() => handleSetFindYourIokeeData('electricCars', true)}
					/>
				)}
				<SvgIcon sx={{ fontSize: { md: 100, xs: 80, sm: 80 } }} color="inherit" component="div" inheritViewBox>
					<ElectricCarIcon />
				</SvgIcon>
				{!isNumberOfCarView && (
					<CheckboxCard
						selected={!findYourIokeeData.electricCars}
						title={t('no').toUpperCase()}
						onClick={() => handleSetFindYourIokeeData('electricCars', false)}
					/>
				)}
			</Stack>
			{
				isNumberOfCarView && (
					<Stack direction="row" spacing={6} alignItems="center">
						<DefaultDropdown
							options={['1', '2 - 6', '7 - 10', '11 - 20', '20+']}
							renderOption={(option) => option}
							value={findYourIokeeData.electicCarsQuantity}
							renderOptionLabel={(option) => `${option || 'How many'}`}
							handleSelect={(value) => handleNumberOfCarsChange(value)}
						/>
					</Stack>
				)
			}
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton onClick={handleContinueClick} variant="contained" endIcon={<ArrowForward />}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default ElectricCarSection;
