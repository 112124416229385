import Dimens from 'assets/dimens';
import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import { TitleText } from 'common/presentation/components';
import { ArrowCategories } from 'assets/icons';
import './category-card.css';

interface Props {
	title: string
	image: string
	onClick: () => void
}

const CategoryCard: React.FC<Props> = (props) => {
	const { title, image, onClick } = props;
	const theme = useTheme();
	return (
		<Box
			className="categoryCard"
			onClick={onClick}
			sx={{
				width: Dimens.CATEGORY_CARD_HEIGHT,
				height: Dimens.CATEGORY_CARD_WIDTH,
				border: `1.5px solid ${theme.palette.textMain.main}`,
				borderRadius: Dimens.SMALL_IMAGE_BORDER_RADIUS,
				cursor: 'pointer',
				transition: 'all 0.25s',
				'&:hover': {
					border: `1.5px solid ${theme.palette.primary.main}`,
					boxShadow: '3px 3px 6px 0px rgba(77, 255, 255, 0.6)',
				},
			}}
		>
			<Stack
				sx={{ height: Dimens.CATEGORY_CARD_ICON_CONTAINER_ICON }}
				direction="row"
				justifyContent="space-between"
			>
				<Stack justifyContent="center" sx={{ height: '100%', pl: 2 }}>
					<TitleText
						sx={{
							fontSize: { md: Dimens.TITLE_TEXT_S, xs: Dimens.TITLE_TEXT_S_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
						}}
					>
						{title.toUpperCase()}
					</TitleText>
				</Stack>
				<Stack
					className="cornerIcon"
					alignItems="center"
					justifyContent="center"
					sx={{
						borderLeft: `1.5px solid ${theme.palette.textMain.main}`,
						borderBottom: `1.5px solid ${theme.palette.textMain.main}`,
						borderBottomLeftRadius: Dimens.CATEGORY_CARD_ICON_CONTAINER_RADIUS,
						width: Dimens.CATEGORY_CARD_ICON_CONTAINER_ICON,
						transition: 'background-color 0.25s ease',
					}}
				>
					<ArrowCategories />
				</Stack>
			</Stack>
			<Stack sx={{ height: '100%', width: '100%', p: 2 }}>
				<img src={image} alt="Product category" />
			</Stack>
		</Box>
	);
};

export default CategoryCard;
