import Dimens from 'assets/dimens';
import {
	Box, Divider, Stack, SvgIcon, Typography, styled, useTheme,
} from '@mui/material';
import React from 'react';
import {
	ArrowUp,
	// Facebook,
	Instagram,
	LinkedIn,
	logo,
} from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import i18next from 'i18next';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import StyledSwitch from '../styled-switch/StyledSwitch';

const AppFooter = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { language } = i18next;
	const cookie = LocalStorageWrapper.get(LocalStorageKeys.COOKIES);
	const [show, setShow] = React.useState(cookie ?? false);

	type TextProps = {
		weight?: string
	}
	const FooterText = styled(Typography)<TextProps>(({ weight }) => ({
		fontWeight: weight ?? Dimens.FONT_WEIGHT_BOLD,
		fontSize: Dimens.TEXT_DEFAULT,
	}));

	const handleBackToTopClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleOpenEmail = () => {
		window.open('mailto:iokee@supportiokee.pt');
	};

	const handleClick = () => {
		if (language === 'pt') {
			window.open('https://iokee.io/cookie_pt.html', '_blank');
		} else {
			window.open('https://iokee.io/cookie_en.html', '_blank');
		}
	};

	const handleCookieToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setShow(checked);
		LocalStorageWrapper.set(LocalStorageKeys.COOKIES, checked);
	};

	return (
		<footer>
			<Box sx={{
				pl: { md: Dimens.APP_BAR_PADDING_HORIZANTAL, xs: Dimens.APP_BAR_PADDING_HORIZANTAL_MOBILE },
				pr: { md: Dimens.APP_BAR_PADDING_HORIZANTAL, xs: Dimens.APP_BAR_PADDING_HORIZANTAL_MOBILE },
				pt: Dimens.FOOTER_PADDING_VERTICAL,
				pb: { md: Dimens.FOOTER_PADDING_VERTICAL, xs: Dimens.FOOTER_PADDING_VERTICAL_MOBILE },
			}}
			>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-around">
					<Box>
						<img src={logo} alt="logo" />
						<FooterText
							sx={{ mt: { md: 2, xs: 2.5 } }}
							color={theme.palette.textMain.main}
						>
							{t('general_inquiries')}
						</FooterText>
						<FooterText
							sx={{ mt: 1, cursor: 'pointer' }}
							color={theme.palette.primary.main}
							onClick={handleOpenEmail}
						>
							hello@iokee.pt
						</FooterText>
					</Box>
					<Stack direction="row" justifyContent="space-between" sx={{ width: '40%' }}>
						<Box sx={{ display: { md: 'block', xs: 'none' } }}>
							<Link to={AppLinks.STORE} style={{ textDecoration: 'none' }}>
								<FooterText
									sx={{ mt: 2, color: theme.palette.primary.main }}
								>
									{t('products').toUpperCase()}
								</FooterText>
							</Link>
							<FooterText
								sx={{ mt: 1 }}
								color={theme.palette.textMain.main}
							>
								{t('iokee_single_phase_analyser')}
							</FooterText>
							<FooterText
								sx={{ mt: 1 }}
								color={theme.palette.textMain.main}
							>
								{t('iokee_three_phase_analyser')}
							</FooterText>
							<FooterText
								sx={{ mt: 1 }}
								color={theme.palette.textMain.main}
							>
								{t('hub').toUpperCase()}
							</FooterText>
							<FooterText
								sx={{ mt: 1 }}
								color={theme.palette.textMain.main}
							>
								{t('wall_box_single_phase')}
							</FooterText>
							<FooterText
								sx={{ mt: 1 }}
								color={theme.palette.textMain.main}
							>
								{t('wall_box_tri_phase')}
							</FooterText>
						</Box>
						<Box sx={{ mt: { md: 0, xs: 5 } }}>
							<Link to={AppLinks.MOBILE_APP} style={{ textDecoration: 'none' }}>
								<FooterText
									sx={{ mt: 2 }}
									color={theme.palette.primary.main}
								>
									{t('iokee_mobile').toUpperCase()}
								</FooterText>
							</Link>
							<FooterText
								sx={{ mt: 2 }}
								color={theme.palette.primary.main}
							>
								{t('company').toUpperCase()}
							</FooterText>
							<Link to={AppLinks.COMMUNITY} style={{ textDecoration: 'none' }}>
								<FooterText
									sx={{ mt: 2 }}
									color={theme.palette.primary.main}
								>
									{t('community').toUpperCase()}
								</FooterText>
							</Link>
							<Link to={AppLinks.CONTACT_US} style={{ textDecoration: 'none' }}>
								<FooterText
									sx={{ mt: 2 }}
									color={theme.palette.primary.main}
								>
									{t('contact_us').toUpperCase()}
								</FooterText>
							</Link>
						</Box>
					</Stack>
					<Box sx={{ mt: { md: 0, xs: 5 } }}>
						<FooterText
							sx={{
								mt: 2,
								color: { md: theme.palette.primary.main, xs: theme.palette.textMain.main },
								textTransform: { md: 'uppercase', xs: 'none' },
							}}
						>
							{t('follow_us').toUpperCase()}
						</FooterText>
						<Stack direction="row" sx={{ mt: 3 }}>
							<Link
								to="https://www.linkedin.com/company/iokee/"
								target="_blank"
								style={{ textDecoration: 'none' }}
							>
								<SvgIcon component="div">
									<LinkedIn />
								</SvgIcon>
							</Link>
							<Link
								to="https://www.instagram.com/iokee.pt/"
								target="_blank"
								style={{ textDecoration: 'none' }}
							>
								<SvgIcon component="div" sx={{ ml: 3 }}>
									<Instagram />
								</SvgIcon>
							</Link>
						</Stack>
					</Box>
				</Stack>
				<Divider sx={{ mt: 16, mb: 4, display: { md: 'block', xs: 'none' } }} />
				<Stack
					direction={{ md: 'row', xs: 'column-reverse' }}
					sx={{ mt: { md: 0, xs: 3 } }}
					spacing={{ xs: 2, md: 0 }}
					justifyContent="space-between"
					alignItems="center"
				>
					<FooterText color={theme.palette.textMain.main}>
						© 2023
						{' '}
						{t('all_rights_reserved')}
						, IOKEE.
					</FooterText>
					<Stack direction="row" alignItems="center">
						<StyledSwitch checked={!!show} onChange={handleCookieToggle} />
						<FooterText
							sx={{ ml: 1, cursor: 'pointer' }}
							color={theme.palette.textMain.main}
							weight={Dimens.FONT_WEIGHT_LIGHTER}
							onClick={handleClick}
						>
							{t('cookie_policy')}
						</FooterText>
					</Stack>
					<FooterText color={theme.palette.textMain.main} weight={Dimens.FONT_WEIGHT_LIGHTER}>
						{t('data_protection_policy')}
					</FooterText>
					<Stack
						direction="row"
						alignItems="flex-end"
						sx={{ cursor: 'pointer' }}
						onClick={handleBackToTopClick}
					>
						<FooterText color={theme.palette.primary.main}>
							{t('back_to_top')}
						</FooterText>
						<SvgIcon component="div" sx={{ ml: 1 }}>
							<ArrowUp />
						</SvgIcon>
					</Stack>
				</Stack>
			</Box>
		</footer>
	);
};

export default AppFooter;
