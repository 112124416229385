import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import { BodyText, DefaultButton, TitleText } from 'common/presentation/components';
import { iokeeGif } from 'assets/images';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
// import DefaultButton from 'common/presentation/components/default-button/DefaultButton';
// import { InclinedArrow } from 'assets/icons';

const AboutIokee = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('sm'));
	const navigate = useNavigate();

	const handleNavigateToAboutUs = () => {
		navigate(AppLinks.ABOUT_US_PAGE);
	};

	return (
		<Stack
			direction="column"
			sx={{
				justifyContent: 'center',
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			spacing={2}
			component="section"
		>
			{/* First row */}
			<Stack>
				<TitleText
					sx={{
						fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
					}}
					dangerouslySetInnerHTML={{ __html: t('the_universe_iokee').toUpperCase() }}
				/>
			</Stack>

			{/* Subtext */}
			<Stack>
				<BodyText
					sx={{
						fontStyle: 'italic',
						fontSize: { md: Dimens.SUBTITLE_TEXT, xs: Dimens.SUBTITLE_TEXT_MOBILE },
					}}
				>
					{t('the_energy_power').toUpperCase()}
				</BodyText>
			</Stack>

			{/* Second row */}
			<Stack
				direction={{ md: 'row', sm: 'column', xs: 'column' }}
				justifyContent="space-around"
				sx={{
					pt: {
						md: Dimens.APP_BAR_PADDING_HORIZANTAL,
						xs: 0,
					},
				}}
			>
				<Stack alignItems="center">
					<img src={iokeeGif} alt="Iokee animating" width={isMd ? '100%' : '50%'} />
				</Stack>
				<Stack sx={{ width: { md: '65%', xs: '100%' } }} justifyContent="center">
					<BodyText size={Dimens.BODY_TEXT_L}>
						{t('Our_mission_is_to_provide_solutions')}
					</BodyText>
					<BodyText
						size={Dimens.BODY_TEXT_M}
						sx={{ pt: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION_MOBILE } }}
					>
						{t('to_achieve_this_goal')}
					</BodyText>
					<Stack alignItems={{ md: 'flex-start', sm: 'flex-end', xs: 'flex-end' }} sx={{ pt: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION_MOBILE } }}>
						<DefaultButton onClick={handleNavigateToAboutUs}>
							{t('about_us')}
						</DefaultButton>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default AboutIokee;
