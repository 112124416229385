import React from 'react';
import {
	Box, Stack, TextField, IconButton, InputAdornment, MenuItem,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import ClientDatePicker from 'common/presentation/components/date-picker/ClientDatePicker';
import { useWallbox } from '../provider/wallbox.provider';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import moment from 'moment';
import { IDevice } from '@/features/client-device-list/entities/device';

const WallboxFilterSection: React.FC = () => {
	const { t } = useTranslation('translations');
	const [startDate, setStartDate] = React.useState<Date | null>(
		moment().startOf('month').toDate(),
	);
	const [endDate, setEndDate] = React.useState<Date | null>(
		moment().toDate(),
	);
	const [selectedUser, setSelectedUser] = React.useState('all_user');
	const { users, getWallboxUsers, setChargeParams } = useWallbox();

	const wallBoxUser = LocalStorageWrapper.get(LocalStorageKeys.DEVICE) as IDevice;

	useEffectCustom(() => {
		// Use the correct ID from wallBoxUser to fetch the users
		if (wallBoxUser?.userProductID) {
			getWallboxUsers(wallBoxUser.userProductID);
		}
	}, [wallBoxUser?.userProductID, getWallboxUsers]);

	const handleSearch = () => {
		const apiParams = {
			id: wallBoxUser?.board?.boardID,
			begin: startDate ? moment(startDate).format('YYYYMMDD') : undefined,
			end: endDate ? moment(endDate).format('YYYYMMDD') : undefined,
			initiator: selectedUser !== 'all_user' ? parseInt(selectedUser, 10) : undefined,
			page: 1,
			size: 10,
		};

		setChargeParams(apiParams);
	};

	useEffectCustom(() => {
		handleSearch();
	}, []);

	return (
		<Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
			<Stack direction={{ md: 'row', xs: 'column' }} spacing={2} alignItems="center">
				<ClientDatePicker
					label={t('from')}
					value={startDate}
					onChange={(newValue) => setStartDate(newValue)}
				/>
				<ClientDatePicker
					label={t('until')}
					value={endDate}
					onChange={(newValue) => setEndDate(newValue)}
				/>

				<TextField
					select
					size="small"
					value={selectedUser}
					onChange={(e) => setSelectedUser(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<PersonIcon />
							</InputAdornment>
						),
					}}
					sx={{ minWidth: 200 }}
				>
					<MenuItem value="all_user">{t('all_user')}</MenuItem>
					{users.map((user) => (
						<MenuItem key={user.userID} value={user.userID.toString()}>
							{user.name}
						</MenuItem>
					))}
				</TextField>

				<IconButton onClick={handleSearch} sx={{ backgroundColor: '#00E5FF' }}>
					<SearchIcon />
				</IconButton>
			</Stack>
		</Box>
	);
};

export default WallboxFilterSection;
