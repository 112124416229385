import Dimens from 'assets/dimens';
import {
	Box,
	Stack,
	Step,
	StepConnector,
	StepIconProps,
	StepLabel,
	Stepper,
	stepConnectorClasses,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';

interface Props {
	activeStep: number;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 13,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
			'linear-gradient(90deg, rgba(0, 255, 255, 1) 50%, rgba(0, 0, 48, 1)50%)',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: theme.palette.primary.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 6,
		border: 0,
		backgroundColor: theme.palette.textMain.main,
		borderRadius: 5,
	},
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.textMain.main,
	zIndex: 1,
	color: '#84848C',
	width: 30,
	height: 30,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '12px',
	fontWeight: Dimens.FONT_WEIGHT_BOLD,
	...(ownerState.active && {
		backgroundColor: theme.palette.textMain.main,
		color: '#84848C',
	}),
	...(ownerState.completed && {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.textMain.main,
	}),
}));

const ColorlibStepIcon = (props: StepIconProps) => {
	const {
		active,
		completed,
		className,
		icon,
	} = props;

	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icon}
		</ColorlibStepIconRoot>
	);
};

const steps = [
	'Select campaign settings',
	'Create an ad group',
	'Create an ad',
	'Select campaign settings',
	'Create an ad gro',
	'Create an a',
];

const CustomStepper: React.FC<Props> = (props) => {
	const { activeStep } = props;
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Stack
			alignItems="center"
			spacing={4}
		>
			<Box sx={{ width: { md: '60%', xs: '100%', sm: '100%' } }}>
				<Stepper
					alternativeLabel
					activeStep={activeStep}
					connector={isMd ? <ColorlibConnector /> : null}
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel StepIconComponent={ColorlibStepIcon} />
						</Step>
					))}
				</Stepper>
			</Box>
		</Stack>
	);
};

export default CustomStepper;
