/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import {
	storeDelete,
	storeGet,
	storePatch,
	storePost,
} from 'common/data/remote/store-requests';
import { IBackofficeQuestion, IBackofficeQuestionRequest } from '../entities/question.modal';
import { Endpoints } from 'assets/Endpoints';
import { ErrorResponseBase } from 'common/domain/entities/error-response';
import { ApiResponse } from '@/common/data/entities/api-response';

export class BackofficeQuestionApiImpl {
	async addBackofficeQuestion(params: IBackofficeQuestionRequest): Promise<IBackofficeQuestion> {
		try {
			const req = await storePost({
				url: Endpoints.STORE_QUESTION,
				data: params,
			});
			const { data } = req;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async editBackofficeQuestion(params: IBackofficeQuestionRequest, id: string): Promise<IBackofficeQuestion> {
		try {
			const req = await storePatch({
				url: Endpoints.STORE_QUESTION_BY_ID.format(id),
				data: params,
			});
			const { data } = req;
			return data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getBackofficeQuestions(): Promise<IBackofficeQuestion[]> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_QUESTION,
			});
			const data = resp.data as ApiResponse<IBackofficeQuestion[]>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async deleteQuestionById(id: number): Promise<string> {
		try {
			const resp = await storeDelete({
				url: Endpoints.STORE_QUESTION_BY_ID.format(id),
			});
			const data = resp.data as ApiResponse<string>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}

	async getQuestionById(id: string): Promise<IBackofficeQuestion> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_QUESTION_BY_ID.format(id),
			});
			const data = resp.data as ApiResponse<IBackofficeQuestion>;
			return data.data;
		} catch (error: any) {
			const resError = error?.response?.data?.responseErrors[0] as ErrorResponseBase;
			const errorToReturn = {
				title: resError.message,
				message: resError.detailMessage,
				code: error.message,
			};
			throw errorToReturn;
		}
	}
}
