import { BodyText, TitleText } from 'common/presentation/components';
import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import ProductItem from 'features/products/presentation/components/ProductItem';
import { iokeeMonoFront } from 'assets/images/iokee-images';
import AppLinks from 'assets/applinks.routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardArticle from 'features/community/presentation/iokee-community/components/CardArticle';
import useCommunity from 'features/community/presentation/provider/community.provider';
import i18n from 'assets/translations';
import NoSearchResults from './components/NoSearchResults';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

const SearchResult = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { getArticles, articles } = useCommunity();
	const [searchParams] = useSearchParams();
	const [searchText] = useState(searchParams.get('query') || '');
	const lang = i18n.language.substring(0, 2);
	const [noResults] = useState(false);

	useEffectCustom(() => {
		getArticles(lang);
	}, []);

	if (noResults) return <NoSearchResults searchText={searchText} />;

	return (
		<Box
			sx={{
				pt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<BodyText
				dangerouslySetInnerHTML={{ __html: t('found_search_text').format(searchText) }}
				sx={{
					fontSize: {
						md: Dimens.BODY_TEXT_XL,
						xs: Dimens.BODY_TEXT_XL_MOBILE,
					},
					textTransform: 'uppercase',
					fontStyle: 'italic',
				}}
			/>
			<Box
				sx={{
					pt: Dimens.APP_BAR_MARGIN_BOTTOM,
				}}
			>
				<TitleText
					sx={{
						fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
				>
					{t('store').toUpperCase()}
				</TitleText>
				<Stack
					direction={{ md: 'row', sm: 'column', xs: 'column' }}
					spacing={2}
					sx={{
						pt: {
							md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
						},
					}}
				>
					<ProductItem
						productImage={iokeeMonoFront}
						title={t('analyzer_monophase')}
						price={199.99}
						handleSeeMoreClick={() => navigate(AppLinks.PRODUCT)}
					/>
					<ProductItem
						productImage={iokeeMonoFront}
						title={t('analyzer_monophase')}
						price={199.99}
						handleSeeMoreClick={() => navigate(AppLinks.PRODUCT)}
					/>
				</Stack>
			</Box>
			<Box
				sx={{
					pt: Dimens.APP_BAR_MARGIN_BOTTOM,
				}}
			>
				<TitleText
					sx={{
						fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
				>
					{t('user_stories').toUpperCase()}
				</TitleText>
				<Stack
					direction={{ md: 'row', sm: 'column', xs: 'column' }}
					spacing={2}
					sx={{
						pt: {
							md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
						},
					}}
				>
					{articles.map((el) => (
						<CardArticle
							article={el}
							onNavigate={(id) => navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id }))}
						/>
					))}
				</Stack>
			</Box>
		</Box>
	);
};

export default SearchResult;
