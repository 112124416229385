import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack } from '@mui/material';
import React from 'react';
import CheckboxCard from '../components/CheckboxCard';
import { ApartmentIcon, HomeIcon } from 'assets/find-your-iokee-icons';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { isValueEntered } from 'utils/validation';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
}

const PrivateSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick } = props;
	const { t } = useTranslation('translations');
	const { handleSetFindYourIokeeData, findYourIokeeData } = useFindYourIokee();
	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 5 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('choose_your_home_html').toUpperCase() }}
			/>
			<Stack
				direction="row"
				justifyContent={{
					md: 'center',
					xs: 'space-between',
					sm: 'space-between',
				}}
				spacing={{ md: 4, xs: 2 }}
			>
				<CheckboxCard selected={findYourIokeeData.homeType === 0} title={t('house').toUpperCase()} icon={<HomeIcon />} onClick={() => handleSetFindYourIokeeData('homeType', 0)} />
				<CheckboxCard selected={findYourIokeeData.homeType === 1} title={t('apartment').toUpperCase()} icon={<ApartmentIcon />} onClick={() => handleSetFindYourIokeeData('homeType', 1)} />
			</Stack>
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton disabled={!isValueEntered(findYourIokeeData.homeType)} variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default PrivateSection;
