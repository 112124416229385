import Dimens from 'assets/dimens';
import {
	BodyText,
	CounterButton,
	DefaultButton,
	TitleText,
} from 'common/presentation/components';
import {
	Box,
	Stack,
	SvgIcon,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { HouseDivisionIcon } from 'assets/find-your-iokee-icons';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { isValueEntered } from 'utils/validation';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
}

const DivisionSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { handleSetFindYourIokeeData, findYourIokeeData } = useFindYourIokee();

	const handleHouseDivisionsChange = (newVal: number) => {
		if (newVal > 0) {
			const mFinalValue = (findYourIokeeData.houseDivisions || 0) + 1;
			handleSetFindYourIokeeData('houseDivisions', mFinalValue);
		} else if (newVal < 0) {
			const mFinalValue = findYourIokeeData.houseDivisions <= 0
				? findYourIokeeData.houseDivisions : (findYourIokeeData.houseDivisions || 0) - 1;
			handleSetFindYourIokeeData('houseDivisions', mFinalValue);
		}
	};

	const infoText = (
		<BodyText
			textAlign={{ md: 'end', xs: 'start', sm: 'start' }}
			sx={{
				fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_S },
			}}
		>
			{t('house_divisions_info')}
		</BodyText>
	);

	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 4 }}>
			<Box>
				<TitleText
					sx={{
						fontStyle: 'italic',
						fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
					}}
					dangerouslySetInnerHTML={{ __html: t('house_divisions_html').toUpperCase() }}
				/>
				{isMd && infoText}
			</Box>
			<Stack alignItems="center" spacing={{ md: 6, xs: 2, sm: 2 }}>
				<SvgIcon sx={{ fontSize: { md: 100, xs: 80, sm: 80 } }} color="inherit" component="div" inheritViewBox>
					<HouseDivisionIcon />
				</SvgIcon>
				<CounterButton
					increment={() => handleHouseDivisionsChange(1)}
					decrement={() => handleHouseDivisionsChange(-1)}
					count={findYourIokeeData.houseDivisions}
				/>
			</Stack>
			{!isMd && infoText}
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton disabled={!isValueEntered(findYourIokeeData.houseDivisions)} variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default DivisionSection;
