import Dimens from 'assets/dimens';
import {
	Box, Stack, useMediaQuery, useTheme, Grid,
} from '@mui/material';
import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import { aboutUs } from 'assets/images';

const AboutUsPage = () => {
	// const { loading, highlightedComment, getComments } = useCommunity();
	const theme = useTheme();
	const isMd = useMediaQuery(() => theme.breakpoints.up('md'));
	const { t } = useTranslation('translations');

	return (

		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<Stack direction="row">
				<TitleText
					size={Dimens.TITLE_TEXT_L}
					sx={{ fontStyle: 'italic' }}
					dangerouslySetInnerHTML={{ __html: t('about_us_title').toUpperCase() }}
				/>

			</Stack>

			<Stack direction={{ md: 'row', xs: 'column' }} spacing={12} sx={{ mt: Dimens.TITLE_BODY_SPACING }}>
				<img
					style={{ borderRadius: '33px 1px 27px 0px / 27px 0px 33px 0px' }}
					src={aboutUs}
					alt="about us "
					width={isMd ? Dimens.REGISTRATION_PAGE_ROW_WIDTH : Dimens.CART_ITEM_IMAGE_WIDTH}
					height={Dimens.ABOUT_US_IMAGE_HEIGHT}
				/>

				<Stack sx={{
					width: {
						md: Dimens.REGISTRATION_SIDE_ILLUSTRATION_TITLE_WIDTH,
						xs: Dimens.COMMUNITY_CARD_WIDTH,
					},
				}}
				>
					<TitleText fontWeight="bold" sx={{ pt: 2 }}>
						{t('green_manifesto').toUpperCase()}
					</TitleText>
					<BodyText sx={{ pt: 4 }}>
						{t('about_us_description')}
					</BodyText>
					<BodyText sx={{ pt: 4 }}>
						{t('about_us_description_para_two')}
					</BodyText>
				</Stack>
			</Stack>
			<Box sx={{ mt: { md: 18, sm: 4 } }}>
				<Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 19 }}>
					<Grid item xs={12} sm={6}>
						<BodyText dangerouslySetInnerHTML={{ __html: t('we_care') }} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<BodyText dangerouslySetInnerHTML={{ __html: t('we_empower') }} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<BodyText dangerouslySetInnerHTML={{ __html: t('we_believe') }} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<BodyText dangerouslySetInnerHTML={{ __html: t('we_support') }} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<BodyText dangerouslySetInnerHTML={{ __html: t('we_provide') }} />
					</Grid>
				</Grid>
			</Box>
		</Box>

	);
};

export default AboutUsPage;
