import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { FindYourIokeeReq } from '../../domain/find-your-iokee';

type FindYourIokeeProps = {
	loading: boolean
	findYourIokeeData: FindYourIokeeReq
	handleSetFindYourIokeeData: (key: string, value: number|boolean|string) => void
}

const FindYourIokeeContext = createContext({} as FindYourIokeeProps);

export const FindYourIokeeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading] = useState(false);
	const [findYourIokeeData, setFindYourIokeeData] = useState({} as FindYourIokeeReq);

	const handleSetFindYourIokeeData = (key: string, value: number|boolean|string) => {
		setFindYourIokeeData({ ...findYourIokeeData, [key]: value });
	};

	const value = useMemo(() => ({
		loading,
		findYourIokeeData,
		handleSetFindYourIokeeData,
	}), [loading, findYourIokeeData]);

	return <FindYourIokeeContext.Provider value={value}>{children}</FindYourIokeeContext.Provider>;
};

export default function useFindYourIokee() {
	return useContext(FindYourIokeeContext);
}
