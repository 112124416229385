/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-catch */
import { storeGet } from 'common/data/remote/store-requests';
import { IStoreProduct } from '../../domain/entities/product';
import { IProductStoreApi } from './product-api';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';

export class ProductStoreApiImpl implements IProductStoreApi {
	async getAllProducts(categoryId: string): Promise<IStoreProduct[]> {
		try {
			const resp = await storeGet({
				url: `${Endpoints.STORE_PRODUCTS}?CategoryId=${categoryId}`,
			});
			const response = resp.data as ApiResponse<IStoreProduct[]>;
			return response.data;
		} catch (err) {
			throw err;
		}
	}

	async getProductById(id: string): Promise<IStoreProduct> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_PRODUCT_BY_ID.format(id),
			});
			const response = resp.data as ApiResponse<IStoreProduct>;
			return response.data;
		} catch (err) {
			throw err;
		}
	}
}
