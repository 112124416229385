import Dimens from 'assets/dimens';
import {
	Box, Stack, SvgIcon,
} from '@mui/material';
import React from 'react';
import ClientBackButton from 'common/presentation/components/client-back-button/ClientBackButton';
import { useNavigate } from 'react-router-dom';
import { BodyText, DefaultDropdown, OptionCheckbox } from 'common/presentation/components';
import { Diagram, Moneys } from 'assets/icons';
import AppLinks from 'assets/applinks.routes';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { IDevice } from 'features/client-device-list/entities/device';
import { useTranslation } from 'react-i18next';
import DeviceOptionCard from './components/DeviceOptionCard';
import useDeviceDetail from './provider/device-detail.provider';
import useEffectCustom from '../../../common/presentation/hooks/useEffectCustom';
import ConsumptionChart from './components/ConsumptionChart';
import CostChart from './components/CostChart';
import { NameAndId } from '@/common/domain/entities/name-and-id';
import { DEVICE_SINGLE_PHASE, DROPDOWN_OPTIONS } from '../domain/entities/energy-consuption';

const DeviceDetailPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { getEnergyConsumption, energyConsumption } = useDeviceDetail();

	const device = LocalStorageWrapper.get(LocalStorageKeys.DEVICE) as IDevice;

	const [isMonophase] = React.useState<boolean>(
		device.product.productID === DEVICE_SINGLE_PHASE,
	);
	const [sensorType, setSensorType] = React.useState(DROPDOWN_OPTIONS[0]);

	const [graphTypeCost, setGraphTypeCost] = React.useState<boolean>(true);

	useEffectCustom(() => {
		getEnergyConsumption({
			userProductId: device.userProductID,
			sensorTypeId: isMonophase ? sensorType.id : 1,
		});
	}, [sensorType]);

	const handleGoBack = () => {
		navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);
	};

	const handleDeviceCardClick = (route: string) => {
		navigate(route);
	};

	const handleGraphTypeChange = (value: boolean) => {
		setGraphTypeCost(value);
	};

	const handleSensorTypeChange = (value: NameAndId) => {
		setSensorType(value);
	};

	return (
		<Box>
			<ClientBackButton onBackClick={handleGoBack} />
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				justifyContent="space-between"
				alignItems={{ md: 'center', xs: 'flex-start' }}
				sx={{ mt: 5 }}
			>
				<OptionCheckbox
					primaryLabel="Custo"
					secondaryLabel="Consumo"
					onClick={handleGraphTypeChange}
				/>
				{isMonophase && (
					<DefaultDropdown
						options={DROPDOWN_OPTIONS}
						renderOption={(option) => option.name}
						renderOptionLabel={(option) => option.name}
						value={sensorType}
						handleSelect={handleSensorTypeChange}
					/>
				)}
			</Stack>
			{graphTypeCost && (
				<CostChart
					graphData={[
						parseFloat(energyConsumption?.monthlyExpectation?.toFixed(2) || '0'),
						parseFloat(energyConsumption?.amountProjection?.toFixed(2) || '0'),
						parseFloat(energyConsumption?.currentAmount?.toFixed(2) || '0'),
						parseFloat(energyConsumption?.previousAmount?.toFixed(2) || '0'),
					]}
				/>

			)}
			{!graphTypeCost && (
				<ConsumptionChart graphData={[
					parseFloat(energyConsumption?.consumptionProjection?.toFixed(2) || '0'),
					parseFloat(energyConsumption?.previousConsumption?.toFixed(2) || '0'),
					parseFloat(energyConsumption?.currentConsumption?.toFixed(2) || '0')]}
				/>
			)}
			<BodyText
				sx={{ fontSize: Dimens.BODY_TEXT_L, fontWeight: Dimens.FONT_WEIGHT_LIGHT, mt: 8 }}
				dangerouslySetInnerHTML={{ __html: t('what_to_look_analyzer').format(device?.name) }}
			/>
			<Stack direction={{ md: 'row', xs: 'column' }} sx={{ mt: 5 }} spacing={3}>
				<DeviceOptionCard
					icon={<SvgIcon><Moneys /></SvgIcon>}
					title="Consultar faturação"
					onClick={() => handleDeviceCardClick(AppLinks.CLIENT_AREA_BILLING)}
				/>
				<DeviceOptionCard
					icon={<SvgIcon><Diagram /></SvgIcon>}
					title="O meu tarifário"
					onClick={() => handleDeviceCardClick(AppLinks.CLIENT_AREA_TARIFF)}
				/>
			</Stack>
		</Box>
	);
};

export default DeviceDetailPage;
