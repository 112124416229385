import React, { createContext, useEffect } from 'react';
import { ICartProduct } from 'common/domain/entities/cart-product';
import { useCartReducer } from './reducer/cart-state.reducer';
import { CartActionKind } from 'common/domain/entities/cart-action';
import useFeedback from './feedback.provider';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from 'utils/storage.utils';

type ActionFunction = (item: ICartProduct) => void

type CartContextProps = {
	cartProducts: ICartProduct[]
	addToCart: ActionFunction
	removeFromCart: ActionFunction
	incrementProduct: ActionFunction
	decrementCart: ActionFunction
	getTotalPrice: () => number
	clearCart: () => void
}

export const CartContext = createContext<CartContextProps>({} as CartContextProps);

export const CartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [cartState, dispatch] = React.useReducer(
		useCartReducer,
		{ cartProducts: [] as ICartProduct[] },
		(initialState) => {
			const persistedState = localStorage.getItem(LocalStorageKeys.CART);
			return persistedState ? JSON.parse(persistedState) : initialState;
		},
	);
	const { addToast } = useFeedback();
	const { t } = useTranslation('translations');

	useEffect(() => {
		localStorage.setItem(LocalStorageKeys.CART, JSON.stringify(cartState));
	}, [cartState]);

	const addToCart = (product: ICartProduct) => {
		const alreadyInCart = cartState.cartProducts.find(
			(item) => item.productId === product.productId,
		);
		if (alreadyInCart) {
			addToast({
				title: t('error'),
				message: t('product_already_in_cart'),
				error: true,
			});
			return;
		}
		addToast({
			title: t('success'),
			message: t('product_added_to_cart'),
			error: false,
		});
		dispatch({ type: CartActionKind.ADD_TO_CART, payload: product });
	};

	const removeFromCart = (product: ICartProduct) => {
		dispatch({ type: CartActionKind.REMOVE_FROM_CART, payload: product });
	};

	const incrementProduct = (product: ICartProduct) => {
		dispatch({ type: CartActionKind.INCREMENT, payload: product });
	};

	const decrementCart = (product: ICartProduct) => {
		dispatch({ type: CartActionKind.DECREMENT, payload: product });
	};

	const clearCart = () => {
		dispatch({ type: CartActionKind.CLEAR_CART, payload: {} as ICartProduct });
	};

	const getTotalPrice = () => cartState.cartProducts.reduce((mTotal, product) => {
		let total = mTotal;
		total += product.price * product.qty;
		return total;
	}, 0 as number);

	const value = React.useMemo(() => ({
		cartProducts: cartState.cartProducts,
		addToCart,
		removeFromCart,
		incrementProduct,
		decrementCart,
		getTotalPrice,
		clearCart,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [cartState.cartProducts]);

	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default function useCart() {
	return React.useContext(CartContext);
}
