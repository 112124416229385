/* eslint-disable @typescript-eslint/ban-ts-comment */
import { placeholderImage } from 'assets/images';
import { Add, Close } from '@mui/icons-material';
import {
	Box,
	FormControlLabel,
	FormGroup,
	IconButton,
	Stack,
	Switch,
	useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	disabled: boolean
	onImageChange: (image: File) => void
	onRemoveClick: () => void
	showRemoveButton: boolean
	imageToPreview?: string
	isMain: boolean
	handleMainChange: (value: boolean) => void
	error?: string
}

const ProductImage: React.FC<Props> = (props) => {
	const {
		disabled,
		onImageChange,
		onRemoveClick,
		showRemoveButton,
		imageToPreview,
		isMain,
		handleMainChange,
		error,
	} = props;
	const theme = useTheme();
	const productImageRef = React.useRef<HTMLInputElement | null>(null);
	const { t } = useTranslation('translations');

	const getImageSource = (src?: string) => {
		if (typeof src === 'string') {
			if (!src) {
				return placeholderImage;
			}
			return src;
		}
		return placeholderImage;
	};

	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				width: '150px',
				height: '150px',
				border:
					error
						? `2px solid ${theme.palette.error.main}`
						: `1px solid ${theme.palette.primary.main}`,
				borderRadius: 2,
				position: 'relative',
			}}
			onClick={() => productImageRef.current?.click()}
		>
			<input
				type="file"
				name="image"
				accept="image/png, image/jpeg"
				ref={productImageRef}
				style={{ display: 'none' }}
				disabled={disabled}
				onChange={(e) => {
					const ev = e.currentTarget.files;
					if (ev) {
						if (ev.length === 0) {
							return;
						}
						onImageChange(ev[0]);
					}
				}}
			/>
			<Add color="success" sx={{ position: 'absolute', opacity: 0.8 }} />
			{showRemoveButton && (
				<IconButton
					sx={{ position: 'absolute', right: -20, top: -20 }}
					disabled={disabled}
					onClick={(e) => {
						e.stopPropagation();
						onRemoveClick();
					}}
				>
					<Close color="error" />
				</IconButton>
			)}
			<img style={{ maxWidth: '100%', objectFit: 'contain' }} alt="Product" src={getImageSource(imageToPreview)} />
			<Box sx={{ position: 'absolute', bottom: -35 }}>
				<FormGroup>
					<FormControlLabel
						control={
							(
								<Switch
									checked={isMain}
									disabled={disabled}
									onClick={(e) => {
										e.stopPropagation();
									}}
									onChange={(e) => {
										e.stopPropagation();
										handleMainChange(!isMain);
									}}
								/>
							)
						}
						label={t('main')}
					/>
				</FormGroup>
			</Box>
		</Stack>
	);
};

export default ProductImage;
