/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { AxiosError } from 'axios';
import { IProductBackofficeRepository } from '../../domain/repository/product.repository';
import { ProductBackofficeApiImpl } from '../remote/product-api-impl';
import { BackofficeProductRequest, ProductBackoffice } from '../../domain/entities/backoffice-product';
import { IAttachmentResp } from 'features/backoffice/common/domain/entities/attachment';
import { normalizeCurrency } from 'utils/price.utils';
import { ErrorResponse } from 'common/domain/entities/error-response';

export class ProductBackofficeRepositoryImpl implements IProductBackofficeRepository {
	productBackofficeApi = new ProductBackofficeApiImpl();

	async addProduct(params: BackofficeProductRequest):
		Promise<Either<ErrorResponse, ProductBackoffice>> {
		try {
			const reqData = { ...params, price: normalizeCurrency(params.price) };
			const mSteps = [...reqData.installationSteps];
			mSteps.forEach((el, index) => {
				el.stepOrder = index + 1;
			});
			reqData.installationSteps = mSteps;
			const addProductRes = await this.productBackofficeApi.addBackofficeProduct(reqData);
			return new Right(addProductRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async updateProduct(params: BackofficeProductRequest, id: string):
		Promise<Either<ErrorResponse, ProductBackoffice>> {
		try {
			const reqData = { ...params, price: normalizeCurrency(params.price) };
			const mSteps = [...reqData.installationSteps];
			mSteps.forEach((el, index) => {
				el.stepOrder = index + 1;
			});
			reqData.installationSteps = mSteps;
			const addProductRes = await this.productBackofficeApi.updateBackofficeProduct(reqData, id);
			return new Right(addProductRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async uploadProductAttachments(params: File): Promise<Either<AxiosError, IAttachmentResp>> {
		try {
			const addAttachmentRes = await this.productBackofficeApi.uploadProductAttachments(params);
			return new Right(addAttachmentRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async getProducts(): Promise<Either<AxiosError, ProductBackoffice[]>> {
		try {
			const getProductsRes = await this.productBackofficeApi.getBackofficeProducts();
			return new Right(getProductsRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async deleteProductById(id: string): Promise<Either<ErrorResponse, ProductBackoffice>> {
		try {
			const deleteProductRes = await this.productBackofficeApi.deleteProductById(id);
			return new Right(deleteProductRes);
		} catch (error: any) {
			return new Left(error);
		}
	}

	async getProductById(id: string): Promise<Either<AxiosError, ProductBackoffice>> {
		try {
			const productByIdRes = await this.productBackofficeApi.getProductById(id);
			return new Right(productByIdRes);
		} catch (error: any) {
			return new Left(error);
		}
	}
}
