import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box } from '@mui/material';

interface GraphProps {
	axisY: number[],
	axisX: string[]
}

const Graph: React.FC<GraphProps> = ({
	axisX,
	axisY,
}) => {
	const series = [
		{
			name: 'kWh',
			data: axisY,
		},
	];

	const options: ApexOptions = {
		chart: {
			type: 'bar',
			height: 200,
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				columnWidth: '50%',
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: axisX,
			labels: {
				style: {
					colors: '#7F7F96',
				},
			},
		},
		yaxis: {
			labels: {
				formatter: (value) => `${value.toFixed(2)} kWh`,
				style: {
					colors: '#7F7F96',
				},
			},
		},
		grid: {
			strokeDashArray: 4,
		},
		colors: ['#00E5FF'],
		tooltip: {
			theme: 'light',
		},
	};

	return (
		<Box sx={{
			width: '100%', height: '200px', borderRadius: '12px', marginTop: '16px',
		}}
		>
			<ReactApexChart options={options} series={series} type="bar" height={400} />
		</Box>
	);
};

export default Graph;
