/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/data/entities/api-response';
import { reqGet, reqPost } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';
import { IDevice } from '../entities/device';
import { IWallboxReportExcelReq } from '../entities/report';

export class DeviceApiImpl {
	async getUserProducts(): Promise<IDevice[]> {
		try {
			const resp = await reqGet({
				url: Endpoints.USER_PRODUCTS,
			});
			const data = resp.data as ApiResponse<IDevice[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async exportWallboxExcel(params: IWallboxReportExcelReq): Promise<Blob> {
		try {
			const resp = await reqPost({
				url: Endpoints.WALLBOX_EXCEL_EXPORT,
				responseType: 'blob',
				data: params,
			});
			const data = resp.data as Blob;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
