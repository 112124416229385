import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import CheckboxCard from '../components/CheckboxCard';
import {
	BusinessIcon,
	PrivateIcon,
} from 'assets/find-your-iokee-icons';
import { ArrowForward } from '@mui/icons-material';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { isValueEntered } from 'utils/validation';

interface Props {
	onContinueClick: () => void
}

const PurposeSection: React.FC<Props> = (props) => {
	const { onContinueClick } = props;
	const { t } = useTranslation('translations');
	const { findYourIokeeData, handleSetFindYourIokeeData } = useFindYourIokee();

	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 5 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('choose_your_purpose_html').toUpperCase() }}
			/>
			<Stack direction="row" spacing={{ md: 4, xs: 2, sm: 2 }}>
				<CheckboxCard onClick={() => handleSetFindYourIokeeData('purpose', 0)} selected={findYourIokeeData.purpose === 0} title={t('private').toUpperCase()} icon={<PrivateIcon />} />
				<CheckboxCard onClick={() => handleSetFindYourIokeeData('purpose', 1)} selected={findYourIokeeData.purpose === 1} title={t('business').toUpperCase()} icon={<BusinessIcon />} />
			</Stack>
			<DefaultButton disabled={!isValueEntered(findYourIokeeData.purpose)} variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
				{t('continue')}
			</DefaultButton>
		</Stack>
	);
};

export default PurposeSection;
