/* eslint-disable react/no-this-in-sfc */
import Dimens from 'assets/dimens';
import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxCard from '../components/CheckboxCard';
import {
	CafeIcon,
	FactoryIcon,
	OfficeIcon,
	StoreIcon,
} from 'assets/find-your-iokee-icons';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import useFindYourIokee from '../provider/find-your-iokee.provider';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
}

const BusinessSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick } = props;
	const { handleSetFindYourIokeeData, findYourIokeeData } = useFindYourIokee();

	const { t } = useTranslation('translations');
	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 2 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('choose_your_business_html').toUpperCase() }}
			/>
			<Stack
				direction="row"
				justifyContent={{ xs: 'center', sm: 'center' }}
				useFlexGap
				flexWrap="wrap"
				spacing={4}
			>
				<CheckboxCard
					selected={findYourIokeeData.businessType === 0}
					title={t('office').toUpperCase()}
					icon={<OfficeIcon />}
					onClick={() => handleSetFindYourIokeeData('businessType', 0)}
				/>
				<CheckboxCard
					selected={findYourIokeeData.businessType === 1}
					title={t('store').toUpperCase()}
					icon={<StoreIcon />}
					onClick={() => handleSetFindYourIokeeData('businessType', 1)}
				/>
				<CheckboxCard
					selected={findYourIokeeData.businessType === 2}
					title={t('factory').toUpperCase()}
					icon={<FactoryIcon />}
					onClick={() => handleSetFindYourIokeeData('businessType', 2)}
				/>
				<CheckboxCard
					selected={findYourIokeeData.businessType === 3}
					title={t('cafe').toUpperCase()}
					icon={<CafeIcon />}
					onClick={() => handleSetFindYourIokeeData('businessType', 3)}
				/>
			</Stack>
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default BusinessSection;
